@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

/* Primary Variables */
:root {
  --primary: "green";
}

/* LOCAL CLASSES */

.css-1j0qgw8-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
  padding-left: 25px !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: -0.5625e !important;
  letter-spacing: 0.00938em !important;
  color: rgba(0, 0, 0, 0.87) !important;
  box-sizing: border-box !important;
  position: relative !important;
  cursor: text !important;
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  width: 100% !important;
  position: relative !important;
  border-radius: 4px !important;
  padding-right: 14px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1j0qgw8-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  /* padding-right: 65px; */
  padding-left: 25px !important;
}

.css-1eed5fa-MuiInputBase-root-MuiInput-root {
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 0.5px !important;
}

.css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
  border: none !important;
}

.css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
  border: none !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
}

.css-1e9nr6u .MuiOutlinedInput-root {
  padding: 0px !important;
  padding-left: 25px !important;
}

/* LIVE CLASSES */

.css-segi59 {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 0.4375em !important;
  letter-spacing: 0.00938em !important;
  color: rgba(0, 0, 0, 0.87) !important;
  box-sizing: border-box !important;
  cursor: text !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  width: 100% !important;
  position: relative !important;
  border-radius: 4px !important;
  padding-right: 14px !important;
}

.css-jcincl::before {
  border: none !important;
}

.css-jcincl::after {
  border: none !important;
}

.css-1yxmbwk {
  padding: 2px !important;
}

.css-jcincl:hover:not(.Mui-disabled, .Mui-error)::before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}
